<template>
  <div class="part__price_wrapper">
    <div class="part__price_top">
      <div class="part__price_name_container">
        <div v-if="item.logo">
          <img :src="item.logo" class="part__price_company_logo" />
        </div>
        <div class="part__price_company_name_container">
          <div
            v-if="item.name"
            class="part__price_company_name"
            @click="openModalAboutCompany()">
            {{item.name}}
          </div>
          <div class="part__price_company_place" v-if="item.address">
            <div>
              <span>{{part.place[language]}}
                <b style="font-weight: 600">
                  {{ item.address }}
                </b>
              </span>
            </div>
            <div :style="{paddingLeft: '7px'}">
              <Point />
            </div>
          </div>
        </div>
      </div>
      <div class="part__price_stars">
        <div class="block block__align-center">
          <Stars :rating="item.mark" />
          <div class="part__price_rating">{{item.mark}}</div>
        </div>
      </div>
    </div>
    <div class="part__price_body">
      <div class="part__price_body_left" v-if="item.imgs && item.imgs.length > 0">
        <div class="part__price_company_pic">
          <Carousel :images="item.imgs"/>
        </div>
      </div>
      <div class="part__price_body_right">
        <div class="part__price_params">
          <div>
            <div class="part__price_params_container">
              <span>{{ part.method[language] }}
                <b style="font-weight: 600">{{ item.technology }}</b>
              </span>
            </div>
            <div class="part__price_params_container">
              <span>{{ part.technology[language] }}
                <b style="font-weight: 600">{{ item.method }}</b>
              </span>
            </div>
            <div class="part__price_params_container">
              <span>{{ part.time[language] }}
                <b style="font-weight: 600">{{ item.time }}</b>
              </span>
            </div>
            <div class="part__price_params_container">
              <span>{{ part.amount_materials[language] }}
                <b style="font-weight: 600">
                  {{ item.amount + ` ${part.unit[language]}` }}
                </b>
              </span>
            </div>
          </div>
          <div class="block__flex block__between block__column">
            <div class="block__flex block__right part__price_discount_container">
              <div class="part__price_discount_card" v-if="item.discount">
                <div class="block__flex block__align-center block__center">
                  <Discount />
                </div>
                <div class="block__flex block__column block__between" style="margin-left: 22px">
                  <div class="part__price_discount_title">
                    {{ part.discount[language] }}
                  </div>
                  <div class="part__price_discount_value">
                    {{ `${item.discount}%` }}
                  </div>
                </div>
              </div>
            </div>
            <div class="part__price_price-1">
              <div class="part__price_full-price_title">
                {{ part.price_for_one[language] }}
              </div>
              <div
                :style="{
                  fontSize: `${setPriceHeight(
                    func.getMoney(item.priceForOne, currency, language),
                    20,
                    30,
                    )}`
                }"
                class="part__price_price-1_value">
                {{func.getMoney(item.priceForOne, currency, language)}}
              </div>
            </div>
          </div>
        </div>
        <div class="part__price_full-price">
          <div class="block__flex block__column block__between">
            <div class="part__price_min-price" v-if="item.min_price_order">
              {{ part.min_price[language] }}
            </div>
            <div class="block block__align-center" v-if="item.min_price_order">
              <CreditCard />
              <div class="part__price_min-price_value">
                {{func.getMoney(item.min_price_order, currency, language)}}
              </div>
            </div>
          </div>
          <div class="block__flex block__column block__between">
            <div class="part__price_full-price_title">{{part.full_price[language]}}</div>
            <div
              :style="{
                fontSize: `${setPriceHeight(
                  func.getMoney(item.price, currency, language),
                  25,
                  40,
                  )}`
              }"
              class="part__price_full-price_value">
              {{func.getMoney(item.price, currency, language)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block" v-if="!hideBasket">
      <div class="part__price_body_left">
        <div class="part__price_body_bottom_left">
          <I5Button :text="part.about_manufacturer[language]"
                    @click="openModalAboutCompany()"
                    :color="'#6F6F6F'"
                    :fontSize="'15px'"
                    :showIcon="true">
            <template v-slot:icon>
              <Manufacture />
            </template>
          </I5Button>
        </div>
      </div>
      <div class="part__price_body_right">
        <div class="part__price_body_bottom_right">
          <I5Button color="#BD3122" @click="pushToBasket" :text="part.basket[language]"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import part from '@/assets/text/part.json';
import func from '@/utilites/functions';
import { query } from '@/utilites/axios';

const Point = () => import('@/assets/pictures/part/card/Marker.svg');
const CreditCard = () => import('@/assets/pictures/part/card/CreditCard.svg');
const Stars = () => import('@/components/stars/Stars.vue');
const Discount = () => import('@/assets/pictures/part/card/Discount.svg');
const Carousel = () => import('@/components/carousel/Carousel.vue');
const I5Button = () => import('@/components/i5Button/I5Button.vue');
const Manufacture = () => import('@/assets/pictures/part/card/manufacture.svg');

export default {
  name: 'PriceCard',
  data() {
    return {
      part,
      func,
    };
  },
  props: {
    item: Object,
    hideBasket: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setPriceHeight(value, min, max) {
      let result = `${max}px`;
      if (value.length > 10) {
        const k = Math.round((max - min) / 5);
        let height = max - ((value.length - 10) * k);
        if (height <= min) {
          height = min;
        }
        result = `${Math.round(height)}px`;
      }
      return result;
    },
    goToBasket() {
    },
    openModalAboutCompany() {
      this.$emit('openAboutModal', this.item);
    },
    pushToBasket() {
      const secretKey = localStorage.getItem('token');
      if (secretKey) {
        query('post', 'getPricesV2', {
          order: true,
          time_stamp: this.$store.state.part.timeStamp,
          file_name: this.$store.state.part.fileName,
          hash: this.$store.state.part.hash,
          amount: this.item.amount,
          lang: this.language,
          currency: this.currency,
          id_method: this.item.id_method,
          id_manuf: this.item.id_manuf,
          printing: {
            density: 50,
            wall: 0.6,
            layerMin: 0.3,
            layerMax: 0.5,
          },
        }, {
          token: localStorage.getItem('token'),
          lang: this.language,
        }).then((item) => {
          this.$store.commit('basket/setOrder', item.manuf);
          this.$router.push('basket');
        });
      } else {
        this.$store.commit('user/setAfterAuthorize', this.pushToBasket);
        this.$store.commit('user/setShowAuthorizeModal', true);
      }
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
    partData() {
      return this.$store.state.part.partData;
    },
    m_lg() {
      return this.$store.state.index.m_lg;
    },
    t_lg() {
      return this.$store.state.index.t_lg;
    },
    width() {
      return this.$store.state.index.width;
    },
    currency() {
      const userParameters = this.$store.state.part.userParameters;
      if (userParameters && userParameters.currency) {
        return userParameters.currency;
      } else {
        return 'RUB';
      }
    },
  },
  components: {
    Point,
    Stars,
    CreditCard,
    Discount,
    Carousel,
    I5Button,
    Manufacture,
  },
};
</script>
